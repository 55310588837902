// @ts-nocheck
import React from "react";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Popover,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import Checkbox from "@material-ui/core/Checkbox";

const status = [
  {
    name: "activated",
  },
  {
    name: "not activated",
  },
  {
    name: "expired",
  },
  {
    name: "disabled",
  },
];

const days = [
  { name: "1" },
  { name: "5" },
  { name: "10" },
  { name: "30" },
  { name: "60" },
];

const DropDown = ({ filters, setFilters, companyList }) => {
  const [openMenu, setOpenMenu] = React.useState(null);
  const [openStatusMenu, setOpenStatus] = React.useState(null);
  const [openDaysMenu, setOpenDaysMenu] = React.useState(null);
  const [openCompanyMenu, setOpenCompanyMenu] = React.useState(null);

  const openStatus = Boolean(openStatusMenu);
  const openDays = Boolean(openDaysMenu);
  const openCompany = Boolean(openCompanyMenu);
  const companyIs = companyList.filter((item) => item !== null);

  const handleChange = (event, key) => {
    event.stopPropagation();
    if (!filters.hasOwnProperty(key)) {
      filters[key] = [];
    }
    const checked = filters[key].includes(event.target.value);
    const uncheck = filters[key].filter((item) => item !== event.target.value);

    return checked
      ? setFilters({ ...filters, [key]: [...uncheck] })
      : setFilters({
          ...filters,
          [key]: [...filters[key], event.target.value],
        });
  };

  return (
    <>
      <Tooltip title="Filters">
        <IconButton
          color={Object.keys(filters).length > 0 ? "primary" : "default"}
          onClick={(event) => setOpenMenu(event.target)}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={openMenu}
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(null)}
      >
        <MenuItem onClick={(event) => setOpenStatus(event.currentTarget)}>
          Status
        </MenuItem>
        <Popover
          open={openStatus}
          anchorEl={openStatusMenu}
          onClose={() => setOpenStatus(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {status.map((item) => (
            <Box
              key={item.name}
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                margin: "4px",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {item.name}
              <Checkbox
                value={item.name}
                checked={filters.status_in?.indexOf(item.name) > -1}
                onChange={(e) => handleChange(e, "status_in")}
                color="primary"
                disabled={
                  filters.status_in?.length >= 2 &&
                  !filters.status_in?.includes(item.name)
                }
              />
            </Box>
          ))}
        </Popover>
        <MenuItem onClick={(event) => setOpenDaysMenu(event.currentTarget)}>
          Days left
        </MenuItem>
        <Popover
          open={openDays}
          anchorEl={openDaysMenu}
          onClose={() => setOpenDaysMenu(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {days.map((item) => (
            <Box
              key={item.name}
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                margin: "4px",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {item.name}
              <Checkbox
                value={item.name}
                checked={filters.days_left_in?.indexOf(item.name) > -1}
                onChange={(e) => handleChange(e, "days_left_in")}
                color="primary"
              />
            </Box>
          ))}
        </Popover>
        {companyIs.length > 0 && (
          <span>
            <MenuItem
              onClick={(event) => setOpenCompanyMenu(event.currentTarget)}
            >
              Company
            </MenuItem>
            <Popover
              open={openCompany}
              anchorEl={openCompanyMenu}
              onClose={() => setOpenCompanyMenu(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {companyIs.map((item, index) => (
                <Box
                  key={`${item}-${index}`}
                  style={{ fontSize: "16px" }}
                  width={300}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: "4px",
                    }}
                  >
                    {item}
                  </Box>
                  <Checkbox
                    value={item}
                    checked={filters.company_name_in?.indexOf(item) > -1}
                    onChange={(e) => handleChange(e, "company_name_in")}
                    color="primary"
                  />
                </Box>
              ))}
            </Popover>
          </span>
        )}
      </Menu>
    </>
  );
};

export default DropDown;
